( function ( $ ) {
    "use strict";

    /**
     * Alternative to $.getScript() which does not ignore the cache, like $.getScript() does, and instead returns a
     * previously cached script if available.
     *
     * Copied from the jQuery docs, verbatim, only the name has been changed (is called jQuery.cachedScript there). See
     * https://api.jquery.com/jquery.getscript/
     *
     * @param   {string} url
     * @param   {Object} [options]
     * @returns {jqXHR}
     */
    $.getScript_cacheEnabled = function( url, options ) {
        // Allow user to set any option except for dataType, cache, and url
        options = $.extend( options || {}, {
            dataType: "script",
            cache: true,
            url: url
        });

        // Use $.ajax() since it is more flexible than $.getScript
        // Return the jqXHR object so we can chain callbacks
        return $.ajax( options );
    };

} )( jQuery );